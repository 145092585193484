import { render, staticRenderFns } from "./ngo-and-support.html?vue&type=template&id=ea1672a4&scoped=true&external"
import script from "./ngo-and-support.js?vue&type=script&lang=js&external"
export * from "./ngo-and-support.js?vue&type=script&lang=js&external"
import style0 from "./ngo-and-support.scss?vue&type=style&index=0&id=ea1672a4&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea1672a4",
  null
  
)

export default component.exports